<template>
  <v-container fluid class="pa-0">
    <v-card flat tile fluid data-v-step="radio_0">
      <v-container>
        <v-select
          v-if="countries && countries.length > 0"
          :items="countries"
          v-model="country"
          single-line
          return-object
          prepend-inner-icon="mdi-filter-variant"
          item-text="i18nName"
          hide-details
        ></v-select>
      </v-container>
    </v-card>
    <radio-list :items="stations" data-v-step="radio_1"></radio-list>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import RemoteControlSelect from "@/components/remoteControl/RemoteControlSelect";
import RadioList from "@/components/RadioList";

export default {
  components: {
    RemoteControlSelect,
    RadioList,
  },
  computed: {
    ...mapState({
      stations: (state) => state.webradio.stations,
      favourites: (state) => state.webradio.items.favourite_radio_stations,
      selectedCountry: (state) => state.webradio.selectedCountry,
      playingStation: (state) => state.webradio.playingStation,
      selectedTv: (state) => state.remoteControl.selectedTv,
      noTv: ({ remoteControl }) => remoteControl.noTv,
    }),
    ...mapGetters("webradio", {
      countries: "getCountryList",
    }),
    country: {
      get() {
        return this.selectedCountry;
      },
      set(val) {
        this.setSelectedCountry(val);
      },
    },
  },
  methods: {
    ...mapActions("webradio", ["getItems"]),
    ...mapMutations("webradio", {
      setSelectedCountry: "SET_SELECTED_COUNTRY",
    }),
  },
  mounted() {
    this.getItems();
  },
};
</script>
